import React from 'react';
import './App.scss';
//import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import toast, { Toaster } from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { local } from 'brownies';

mapboxgl.accessToken = 'pk.eyJ1IjoibnZvbGdhcyIsImEiOiJjbG5raDkzaWwxNHEwMmxtbm9sYjM5YzJoIn0.jvOjeX6XQ5k0Yjb3icpzHw';

const API_ENDPOINT = 'https://pjv7egtj68.execute-api.us-east-1.amazonaws.com/prod';

const urlParams = new URLSearchParams(window.location.search);
const API_KEY = urlParams.get('api-key');

interface WaterSource {
  milemarker: number,
  section: number,
  type: string,
  description: string
}

interface Point {
  coordinates: Array<number>,
  type: 'Point'
}

function translateType(type: string): string {
  let translated = 'Unknown';

  switch (parseInt(type)) {
    case 1: translated = 'Potable spigot'; break;
    case 2: translated = 'Pond/stream (requires filtration)'; break;
    case 3: translated = 'Seasonal/un-reliable (requires filtration)'; break;
    case 4: translated = 'Feature'; break;
  }

  return translated;
}

export default class App extends React.PureComponent {

  mapContainer: React.RefObject<any>;

  state: {
    lat: number, lng: number, addingPoint: boolean, popupBox?: mapboxgl.Popup,
    milemarker: string, section: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10, type: 1 | 2 | 3 | 4, description: string
  };

  map: mapboxgl.Map;

  popup: mapboxgl.Popup;

  constructor(props: any) {
    super(props);
    this.state = { lat: 0, lng: 0, addingPoint: false, milemarker: '', section: 10, description: '', type: 1 };
    this.mapContainer = React.createRef();
  }

  render() {
    return (
      <>
        <div className="sidebar">
          Mile Marker: <img src="boot.svg" alt="Boot" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Other Feature: <span className="dot" style={{ backgroundColor: '#0b521e' }}></span><br />
          Water (spigot - no filtration required): <span className="dot" style={{ backgroundColor: '#1e81b0' }}></span><br />
          Water (pond/creek - requires filtration): <span className="dot" style={{ backgroundColor: '#e28743' }}></span><br />
          Water (seasonal/unreliable - requires filtration): <span className="dot" style={{ backgroundColor: '#eb4034' }}></span><br />
        </div>
        {API_KEY &&
          <div className="sidebar" style={{ top: 125, visibility: this.state.addingPoint ? 'visible' : 'hidden' }}>
            <b>Create new Location of Interest</b><br />
            Mile marker: <input onChange={(e) => { this.setState({ ...this.state, ...{ milemarker: e.target.value } }) }} value={this.state.milemarker} /><br />
            Description: <input onChange={(e) => { this.setState({ ...this.state, ...{ description: e.target.value } }) }} value={this.state.description} /><br />
            Type: <input onChange={(e) => { this.setState({ ...this.state, ...{ type: parseInt(e.target.value) } }) }} value={this.state.type} /><br />
            Section: <input onChange={(e) => { this.setState({ ...this.state, ...{ section: parseInt(e.target.value) } }) }} value={this.state.section} /><br /><br />
            <button onClick={() => { return this.createFeature() }}>Create Feature</button>
          </div>
        }
        <Toaster />
        <div ref={this.mapContainer} className="map-container" style={{ height: '90vh' }} />
      </>
    );
  }

  async createFeature() {
    await fetch(API_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        lat: this.state.lat,
        lng: this.state.lng,
        type: this.state.type,
        section: this.state.section,
        milemarker: parseFloat(this.state.milemarker),
        description: this.state.description
      }),
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': API_KEY
      }
    }).catch(error => {
      toast('Error creating feature - ' + error);
    });

    toast('Feature created!');

    // add the new feature to the map with a hack
    const sourceId = uuidv4();

    this.map.addSource(sourceId, {
      type: 'geojson',
      // Use a URL for the value for the `data` property.
      data: {
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [
            this.state.lng,
            this.state.lat
          ]
        },
        "properties": {
          "section": this.state.section,
          "lng": this.state.lng,
          "description": this.state.description,
          "lat": this.state.lat,
          "milemarker": this.state.milemarker,
          "type": this.state.type
        }
      }
    });

    this.map.addLayer({
      'id': uuidv4(),
      'type': 'circle',
      'source': sourceId,
      'paint': {
        'circle-radius': 8,
        'circle-stroke-width': 2,
        'circle-color': [
          'match',
          ['get', 'type'],
          1,
          '#1e81b0',
          2,
          '#e28743',
          3,
          '#eb4034',
          4,
          '#0b521e',
/* other */ '#000'
        ],
        'circle-stroke-color': 'white'
      }
    });

    this.popup.remove();

    this.setState({ ...this.state, ...{ addingPoint: false, description: '', lat: 0, lng: 0 } });
  }

  componentDidMount() {
    const currentLocation = local.location ?? { center: [-92.46375819322022, 34.84479711807772], zoom: 15 };
    const map = new mapboxgl.Map({
      ...{
        container: this.mapContainer.current,
        style: 'mapbox://styles/nvolgas/clnotqxfz009o01pa92cbaqnt'
      },
      ...currentLocation
    });

    map.on('style.load', () => {
      map.setFog({}); // Set the default atmosphere style
    });

    map.on('load', () => {
      map.addSource('ot-water', {
        type: 'geojson',
        // Use a URL for the value for the `data` property.
        data: `${API_ENDPOINT}?key=13922cbe-810a-47f3-8db4-7329ccb0bce9`
      });

      map.addLayer({
        'id': 'ot-water',
        'type': 'circle',
        'source': 'ot-water',
        'paint': {
          'circle-radius': 8,
          'circle-stroke-width': 2,
          'circle-color': [
            'match',
            ['get', 'type'],
            1,
            '#1e81b0',
            2,
            '#e28743',
            3,
            '#eb4034',
            4,
            '#0b521e',
/* other */ '#000'
          ],
          'circle-stroke-color': 'white'
        }
      });
    });

    map.on('dragend', e => {
      local.location = {
        ...local.location,
        ...{ center: [e.target.getCenter().lng, e.target.getCenter().lat] }
      };
    });

    map.on('zoomend', e => {
      local.location = {
        ...local.location,
        ...{ zoom: e.target.getZoom() }
      };
    });

    if (API_KEY) {
      map.on('click', (e) => {
        console.debug(e);
        this.setState({ ...this.state, ...{ lat: e.lngLat.lat, lng: e.lngLat.lng, addingPoint: true } });

        this.popup = new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setHTML(`<b>New Location</b><br/>Lat: ${e.lngLat.lat}<br/>Long: ${e.lngLat.lng}`)
          .addTo(map)
          .on('close', () => {
            this.setState({ ...this.state, ...{ addingPoint: false } });
          });
      });
    }

    map.on('click', 'ot-water', e => {
      console.debug('Clicked:', e.features[0].properties);
      const waterSource = e.features[0].properties as WaterSource;
      let coordinates = (e.features[0].geometry as Point).coordinates;
      const description = (`
          <h3>${waterSource.description}</h3>
          <p>
              <strong>Mile Marker</strong> ${waterSource.milemarker}<br/>
              <strong>Section</strong> ${waterSource.section}<br/>
              <strong>Type</strong> ${translateType(waterSource.type)}
          </p>
        `);

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new mapboxgl.Popup()
        .setLngLat([coordinates[0], coordinates[1]])
        .setHTML(description)
        .addTo(map);
    });

    this.map = map;
  }
};
